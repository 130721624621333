















































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import COOP from '@/graphql/queries/Coop.graphql';
import COOP_CREATE from '@/graphql/mutations/CoopCreate.graphql';
import COOP_UPDATE from '@/graphql/mutations/CoopUpdate.graphql';
import { required, email, CustomRule } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import { CODES } from '@/utils/constants';
import {
  CoopQuery,
  CoopInfoFragment,
  CoopCreateMutation,
  CoopCreateMutationVariables,
  CoopUpdateMutation,
  CoopUpdateMutationVariables,
  CoopQueryVariables,
  Maybe,
} from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';

const multipleEmailValidator: CustomRule = (value) => {
  // Splitting on whitespace (\s) helps avoid script injection since
  // ES6 doesn't have \A \z anchors.
  let emails: Array<string> = value.split(/[\s,;]/);

  return emails.every(email);
};

export default Vue.extend({
  name: 'AdminCoopsAdd',
  components: {
    AppMain,
    AppForm,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        name: '',
        email: '',
        notify: false,
      },
      coop: null as CoopQuery['coop'],
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        email: { multipleEmailValidator },
      },
    };
  },
  methods: {
    validationMessages,
    fillForm(coop?: Maybe<CoopInfoFragment>) {
      if (!coop) return;

      this.form = {
        name: coop.name ?? '',
        email: coop.email ?? '',
        notify: coop.detentionNotify ?? false,
      };
    },
    async saveCoop() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        if (this.$route.params.id) await this.updateCoop();
        else await this.addCoop();
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    async addCoop() {
      const { name, email, notify } = this.form;
      const detentionNotify = email ? notify : false;

      await this.$apollo.mutate<
        CoopCreateMutation,
        CoopCreateMutationVariables
      >({
        mutation: COOP_CREATE,
        variables: {
          input: {
            name,
            email,
            detentionNotify,
          },
        },
      });
      this.$router.replace({ name: 'admin-coops' });
      this.$notify({
        text: 'Co-op added!',
        type: 'success',
        duration: 6000,
      });
    },
    async updateCoop() {
      if (!this.coop) return;

      const { id } = this.coop;
      const { name, email, notify } = this.form;
      const detentionNotify = email ? notify : false;

      await this.$apollo.mutate<
        CoopUpdateMutation,
        CoopUpdateMutationVariables
      >({
        mutation: COOP_UPDATE,
        variables: {
          input: {
            id,
            name,
            email,
            detentionNotify,
          },
        },
      });
      this.$router.replace({ name: 'admin-coops' });
      this.$notify({
        text: 'Co-op updated!',
        type: 'success',
        duration: 6000,
      });
    },
  },
  apollo: {
    coop: {
      query: COOP,
      variables(): CoopQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<CoopQuery>) {
        if (!data?.coop?.id) this.$router.replace({ name: 'admin-coops' });
        this.fillForm(data.coop);
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.$router.replace({ name: 'admin-coops' });
        }
      },
      skip(): boolean {
        return !this.$route.params.id;
      },
    },
  },
});
